import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/P9tFoP6zMuToU',
    name: 'Giveaway',
    component: () => import('../views/Giveaway.vue'),
  },
  {
    path: '/9pW7vLMuH5ZVUrSr',
    name: 'Payment',
    component: () => import('../views/Payment.vue'),
  },
  {
    path: '/da3YhnoUdwpjbCd4LjeaRj',
    name: 'Payment',
    component: () => import('../views/PaymentGold.vue'),
  },
  {
    path: '/RU7VYyr4RuD4d3AqcFv',
    name: 'Demo',
    component: () => import('../views/Demo.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
