<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import './styles.css';

export default {

};
</script>
